import classNames from "../../utils/classNames";

export const ButtonFormInput = ({
                                  label,
                                  isSubmit,
                                  onClick,
                                  classes,
                                  disabled = false,
                                  isBlue = false,
                                  ...rest
                                }) => {
  return (
      <button
          className={classNames(
              isBlue
                  ? "bg-coag-lightblue-500 hover:bg-coag-lightblue-600"
                  : "bg-gray-200 hover:bg-gray-300",
              disabled &&
              "text-gray-500 bg-gray-300 hover:bg-gray-300 hover:cursor-not-allowed",
              "px-14 py-6 relative rounded-full font-medium leading-4 shadow-sm ",
              classes
          )}
          disabled={disabled}
          onClick={onClick}
          type={`${isSubmit ? "submit" : "button"}`}
          {...rest}
      >
        {label}
      </button>
  );
};
