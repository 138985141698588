import classNames from "../../utils/classNames"


export const ButtonInput = ({label, disabled, isSubmit, onClick, isSolid = true, Icon = null, classes}) => {

  return (
      <button
          className={classNames(classes,
              isSolid ? " bg-coag-blue-500 text-white [&>span>svg]:hover:!text-coag-blue-500 hover:bg-white hover:text-coag-blue-500 hover:border-coag-blue-500 hover:border-[3px]" :
                  "bg-white hover:text-white [&>span>svg]:hover:!text-white text-coag-blue-500 hover:bg-coag-blue-500",
              "px-14 py-2 relative rounded-full border-[3px] border-coag-blue-500 text-sm font-medium leading-4  shadow-sm "
          )}
          disabled={disabled}
          onClick={onClick}
          type={`${isSubmit ? 'submit' : 'button'}`}
      >
        {Icon && (
            <span className="absolute left-4 bottom-[12%]">{<Icon
                className={classNames(isSolid ? "text-white" : "text-coag-blue-500", "h-6 w-6 font-bold")}/>}</span>
        )}
        {label}
      </button>
  )
}