const PatientStatusEnum = {
  0: "Stopped",
  1: "Prompted",
  2: "Restarted",
  3: "Declined",
  4: "Declined - Ongoing",
  5: "Declined - Complete",
}

export default PatientStatusEnum;
