import {UserIcon} from "@heroicons/react/24/outline";


export function EmergencyContactRow({contact, onRowClick}) {
  return (
      <div onClick={onRowClick}
           className="px-3 py-2 flex items-center space-x-2 text-md font-medium bg-gray-200 rounded-full hover:bg-gray-300 hover:cursor-pointer">
        <UserIcon className="w-8 h-8"/>
        <span>{contact.firstname} {contact.lastname} - {contact.relation}</span>
      </div>
  )
}