export default function LandingInfoBanner({ rounded = true }) {

  return (
    <div className={`my-8 py-6 grid grid-cols-1 lg:grid-cols-3 bg-coag-slate text-center ${rounded ? "rounded-lg" : ""} `}>

      <div className='col-span-1 p-4 lg:p-8 flex flex-col items-center'>
        <div className='h-12 w-12 md:h-16 md:w-16 mb-6'>
          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
            viewBox="0 0 16 16">
            <path d="M8,16l-6.9-4V4L8,0l6.9,4v8L8,16z M2,11.5L8,15l6-3.5v-7L8,1L2,4.5V11.5z"></path>
            <g>
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                focusable="false" viewBox="0 0 16 16" width="42%" height="42%" x="29%" y="29%">
                <path
                  d="M16,13l-1.5,2h-13L0,13L6.8,1h2.5L16,13z M13.6,12.8L8,3h0H7.9l-5.5,9.8L2.5,13h11L13.6,12.8z M8,12.8 c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.2,0.6,1.2,1.3S8.7,12.8,8,12.8z M9,5.5L8.8,9.7H7.2L7,5.5H9z"></path>
              </svg>
            </g>
          </svg>
        </div>
        <h2 className='text-xl md:text-3xl font-bold'>You've had a head injury</h2>
        <p className='text-lg md:text-2xl font-bold'>whilst taking anticoagulants</p>
        <p className='mt-4 mx-8 md:mx-2 text-lg md:text-xl text-gray-600'>
          If a CT scan reveals blood inside the head, then in most cases any anticoagulants will be
          stopped to prevent further bleeding.
        </p>
      </div>

      <div className='col-span-1 p-4 lg:p-8 flex flex-col items-center'>
        <div className='h-12 w-12 md:h-16 md:w-16 mb-6'>
          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
            viewBox="0 0 16 16">
            <path d="M8,16l-6.9-4V4L8,0l6.9,4v8L8,16z M2,11.5L8,15l6-3.5v-7L8,1L2,4.5V11.5z"></path>
            <g>
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                focusable="false" viewBox="0 0 16 16" width="42%" height="42%" x="29%" y="29%">
                <path
                  d="M3.2,10.2C2,10.2,1,9.2,1,8s1-2.2,2.2-2.2s2.2,1,2.2,2.2S4.4,10.2,3.2,10.2z M8,10.2c-1.2,0-2.2-1-2.2-2.2 s1-2.2,2.2-2.2s2.2,1,2.2,2.2S9.2,10.2,8,10.2z M12.8,10.2c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2S15,6.8,15,8S14,10.2,12.8,10.2z"></path>
              </svg>
            </g>
          </svg>
        </div>
        <h2 className='text-xl md:text-3xl font-bold'>You paused anticoagulant medication</h2>
        <p className='mt-4 mx-8 md:mx-2 text-lg md:text-xl text-gray-600'>
          Your Doctor will determine how long is needed which can vary from a few days to several weeks
          depending on patient factors.
        </p>
      </div>

      <div className='col-span-1 p-4 lg:p-8 flex flex-col items-center'>
        <div className='h-12 w-12 md:h-16 md:w-16 mb-6'>
          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
            viewBox="0 0 16 16">
            <path d="M8,16l-6.9-4V4L8,0l6.9,4v8L8,16z M2,11.5L8,15l6-3.5v-7L8,1L2,4.5V11.5z"></path>
            <g>
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                focusable="false" viewBox="0 0 16 16" width="42%" height="42%" x="29%" y="29%">
                <path d="M16,3.5L5.5,14L0,8.5L1.5,7l4,4l9-9L16,3.5z"></path>
              </svg>
            </g>
          </svg>
        </div>
        <h2 className='text-xl md:text-3xl font-bold'>When it's time to restart, we'll send you a prompt</h2>
        <p className='mt-4 mx-8 md:mx-2 text-lg md:text-xl text-gray-600'>
          We'll send you a record of the information, and then follow up with you when it becomes time to
          restart your anticoagulants
        </p>
      </div>

    </div>
  );
}