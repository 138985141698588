import React from 'react';
import classNames from '../../utils/classNames';

// text: The text to display on the button
// onClick: The function to run when the button is clicked
// classes: Any extra classes to add to the button (usually TailwindCSS classes)

const PillButton = ({text, onClick, classes, isSolid}) => {

  // Explicit button styling
  const buttonStyle = classNames(
      `${classes}`,
      'px-6 py-3 relative rounded-full border-2 border-coag-blue-500',
      'text-sm font-extrabold leading-4 shadow-sm',
      'hover:ring-4 hover:ring-yellow-200',
      isSolid ? 'bg-coag-blue-500 text-white' : 'bg-white text-coag-blue-500'
  );

  return (
      <button className={buttonStyle} onClick={onClick}>
        {text}
      </button>
  );
};

export default PillButton;