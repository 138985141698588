import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {NotifierContextProvider} from "react-headless-notifier";

import {PageNameProvider} from "./contexts/PageTitleContext";
import reportWebVitals from "./reportWebVitals";
import Layout from "./layouts/Layout";
import ErrorBoundry from "./pages/Error/ErrorBoundry";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import ConfirmResume from "./pages/Prompt/ConfirmResume";
import RequireAuth from "./pages/Login/RequireAuth";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import PromptLayout from "./layouts/PromptLayout";
import Frailty from "./pages/Prompt/Frailty";
import Success from "./pages/Prompt/Success";
import ReportIssue from "./pages/Prompt/ReportIssue";
import IssueSubmitted from "./pages/Prompt/IssueSubmitted";
import Landing from "./pages/Landing/Landing";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login/>,
    errorElement: <ErrorBoundry/>,
  },
  {
    path: "confirm-email",
    element: <ConfirmEmail/>,
  },
  {
    path: "prompt",
    element: <PromptLayout/>,
    children: [
      {
        path: "resume/:token/:source",
        element: <ConfirmResume/>,
      },
      {
        path: "frailty/:token/:source",
        element: <Frailty/>,
      },
      {
        path: "success",
        element: <Success/>,
      },
      {
        path: "report-issue/:token/:source",
        element: <ReportIssue/>,
      },
      {
        path: "issue-submitted",
        element: <IssueSubmitted/>,
      },
    ],
  },
  {
    path: "/",
    element: <Layout/>,
    errorElement: <ErrorBoundry/>,
    children: [
      {
        path: "/",
        element: <Landing/>,
      },
      {
        element: <RequireAuth allowedRoles={{isAdmin: true}}/>,
        children: [
          {
            path: "home",
            element: <Home/>,
          },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
    <React.StrictMode>
      <PageNameProvider>
        <QueryClientProvider client={queryClient}>
          <NotifierContextProvider
              config={{
                max: 5,
                position: "topRight",
              }}
          >
            <RouterProvider router={router}/>
            <ReactQueryDevtools initialIsOpen={false}/>
          </NotifierContextProvider>
        </QueryClientProvider>
      </PageNameProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
