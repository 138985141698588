import {useForm} from "react-hook-form";
import {ButtonFormInput} from "../Inputs/ButtonFormInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import useDefaultCRUDHandlers from "./../../hooks/useDefaultCRUDHandlers";
import usePatient from "../../data/usePatient";
import {TextAreaInput} from "../Inputs/TextAreaInput";

export default function AddFreeformNote({patient: notePatient, onSuccess}) {
  const {patient, update, addFreeformNote} = usePatient(notePatient.id);
  const {saveHandlers} = useDefaultCRUDHandlers("Patient");
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const sendFreeformNote = (note) => {
    addFreeformNote.mutate(note, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        onSuccess();
      }
    })

  }

  if (patient.isLoading) {
    return <LoadingWheel/>;
  } else {
    return (
        <>
          <form onSubmit={handleSubmit(sendFreeformNote)}>

            <div>
              <TextAreaInput
                  label="Append a note to this patient's record:"
                  register={register}
                  required
                  registerName="note"
              />
            </div>

            <div className="mt-4 grid grid-cols-1">
              <ButtonFormInput
                  label="Add Note"
                  isSubmit={true}
                  isBlue={true}
                  onClick={() => {
                  }}
              />
            </div>

            {Object.keys(errors).length > 0 && (
                <div className="mt-4 px-4 text-right text-red-500">
                  <p>Please fill in all required fields!</p>
                </div>
            )}
          </form>
        </>
    );
  }
}
