export const TextInput = ({
                            label,
                            register,
                            registerName,
                            className,
                            required = false,
                            cols = 1,
                          }) => {
  return (
      <div
          style={{gridColumn: `span ${cols} / span ${cols}`}}
          className={`flex rounded-full bg-gray-200 ${className}`}
      >
      <span className="flex select-none items-center pl-4 font-semibold">
        {label}
      </span>
        <input
            type="text"
            {...register(registerName, {required: required})}
            className="block flex-1 border-0 bg-transparent py-5 pl-1.5  placeholder:text-gray-400 focus:ring-0"
        />
      </div>
  );
};
