export default function PromptCard({title, preTitle, children, className}) {
  return (
      <div className="mt-6">
        <div
            className={`max-w-7xl mx-auto px-4 py-8 text-white font-medium bg-gray-700 lg:py-14 lg:px-10 ${className}`}>
          <div className="lg:w-7/12 mx-auto space-y-6">
            {preTitle && <p className="text-lg">{preTitle}</p>}
            <p className="text-2xl">{title}</p>
            {children}
          </div>
        </div>
      </div>
  );
}
