export const DateInput = ({label, register, registerName, cols = 1}) => {

  return (
      <div style={{gridColumn: `span ${cols} / span ${cols}`}}
           className={`relative flex items-stretch flex-grow focus-within:z-10`}>
        <label className="font-semibold absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
          {label}
        </label>
        <input
            type="date"
            {...register(registerName)}
            className="pl-28 w-full border-0 bg-gray-200 py-5 rounded-full"
        />
      </div>
  )
}