import {useMutation, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";

export default function useLogin() {
  const queryClient = useQueryClient();
  const {get, post, put, del} = useApiHelper();

  const login = useMutation((loginRequest) => post(`/auth/login`, loginRequest), {
    // onSuccess: () => queryClient.invalidateQueries(["patients"]),
  });


  return {
    login
  };
}
