import {useEffect, useState} from "react";

import {DateTime} from "luxon";

import useAuth from "../../hooks/useAuth";
import LoadingWheel from "../../components/shared/LoadingWheel";
import PatientTable from "../../components/Patients/PatientTable";
import usePatients from "../../data/usePatients";
import ErrorBanner from "../../components/shared/ErrorBanner";
import Flyout from "./../../layouts/Flyout";
import PatientDetails from "../../components/Patients/PatientDetails";
import EditPatient from "../../components/Patients/EditPatient";
import AddFreeformNote from "../../components/Patients/AddFreeformNote";
import FrailtyDescribed from "../../components/Patients/FrailtyDescribed";

export default function Home() {
  const {user, loading: authLoading} = useAuth();
  const {patients} = usePatients();
  const [loading, setLoading] = useState(true);
  const [priorityPatients, setPriorityPatients] = useState([]);
  const [detailsPatient, setDetailsPatient] = useState(undefined);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [editPatient, setEditPatient] = useState(undefined);
  const [editOpen, setEditOpen] = useState(false);
  const [notesPatient, setNotesPatient] = useState(undefined);
  const [notesOpen, setNotesOpen] = useState(false);
  const [frailtyOpen, setFrailtyOpen] = useState(false);

  useEffect(() => {
    if (!patients.isLoading && patients.data && !authLoading && user) {
      let priorityPatients = [
        // Filter out patients that have declined (either 'Declined' or 'Declined - Ongoing')
        ...patients.data.filter((patient) => [3, 4].includes(patient.currentStatus)),
        // Or patients who haven't responded to their prompts
        ...patients.data.filter((patient) => {
          let promptDate = DateTime.fromISO(patient.currentStatusDate);
          return (
              patient.currentStatus === 1 &&
              DateTime.now().diff(promptDate).milliseconds > 0
          );
        }),
      ];

      setPriorityPatients(priorityPatients);
      setLoading(false);
    }
  }, [patients.isLoading, patients.data, authLoading, user]);

  const handlePatientDetailsClick = (patient) => {
    setDetailsPatient(patient);
    setDetailsOpen(true);
  };

  const handlePatientEditClick = (patient) => {
    setEditPatient(patient);
    setDetailsOpen(false);
    setEditOpen(true);
  };

  const handleFreeformNotesClick = (patient) => {
    setNotesPatient(patient);
    setNotesOpen(true);
    setDetailsOpen(false);
  };

  if (patients.isError) {
    return <ErrorBanner message="Failed to get patient list"/>;
  } else if (loading) {
    return <LoadingWheel/>;
  } else {
    return (
        <>
          <Flyout open={frailtyOpen} setOpen={setFrailtyOpen} title={"What does frailty look like?"}
                  subtitle={"It's important that we know how your health is impacting your life so that we can look after you as best as we can. Please find the category that fits you best, or get in touch with your health care provider if unsure."}>
            <FrailtyDescribed/>
          </Flyout>

          {/* Flyout for viewing a patient's history; offers staff a few options */}
          <Flyout
              open={detailsOpen}
              setOpen={setDetailsOpen}
              title={
                detailsPatient
                    ? `${detailsPatient.firstname} ${detailsPatient.lastname}`
                    : ""
              }
              subtitle={
                detailsPatient
                    ? `${detailsPatient.phone} - ${detailsPatient.email}`
                    : ""
              }
          >
            <PatientDetails
                patientId={detailsPatient?.id}
                onEdit={handlePatientEditClick}
                onNote={handleFreeformNotesClick}
                setFrailtyOpen={setFrailtyOpen}
            />
          </Flyout>

          {/* Flyout for editing a patient's information */}
          <Flyout open={editOpen} setOpen={(b) => {
            setEditOpen(b);
            setDetailsOpen(!b);
          }} title="Edit Patient">
            <EditPatient
                patient={editPatient}
                onSuccess={() => {
                  setEditOpen(false);
                  setDetailsOpen(true);
                }}
                setFrailtyOpen={setFrailtyOpen}
            />
          </Flyout>

          {/* Flyout for adding a freeform note to patient's history */}
          <Flyout open={notesOpen} setOpen={(b) => {
            setNotesOpen(b);
            setDetailsOpen(!b);
          }} title="Add Note">
            <AddFreeformNote
                patient={notesPatient}
                onSuccess={() => {
                  setNotesOpen(false);
                  setDetailsOpen(true);
                }}
            />
          </Flyout>

          <div className="mt-6 ">
            <div className="p-8 bg-coag-blue-500">
              <div className="max-w-2xl space-y-2 text-white font-semibold">
                <p className="text-lg ">Hello {user?.displayName}</p>
                <p>
                  The following users needs to resume their medications, but have either
                  declined or haven't responded to their prompts in the past seven days:
                </p>
              </div>
              <PatientTable
                  onRowClick={handlePatientDetailsClick}
                  patients={priorityPatients}
                  isBlue={true}
                  pagination={false}
                  showRestartedToggle={false}
              />
            </div>

            <div className="px-8 py-4">
              <PatientTable
                  onRowClick={handlePatientDetailsClick}
                  patients={patients.data}
              />
            </div>
          </div>
        </>
    );
  }
}
