import {useEffect, useState} from "react";

import {PlusIcon} from "@heroicons/react/24/outline";

import useAuth from "../../hooks/useAuth";
import {ButtonInput} from "../Inputs/ButtonInput";
import AddPatient from "../Patients/AddPatient";
// eslint-disable-next-line
import PillButton from "../Buttons/PillButton";
import {useNavigate} from 'react-router';

export default function Topbar() {
  const {auth, user, loading, handleLogout} = useAuth();
  const [addPatientOpen, setAddPatientOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.isAdmin) setIsAdmin(true);
      });
    }
    // eslint-disable-next-line
  }, [loading])

  //To keep loading wheel from moving around
  if (loading) return <div className="h-16"></div>;

  return (
      <>
        {isAdmin && (
            <AddPatient open={addPatientOpen} setOpen={setAddPatientOpen}/>
        )}
        <div className="top-0 z-30 flex pb-5 pt-5 flex-shrink-0 bg-white shadow">
          <div className="flex flex-col gap-y-4 my-4 md:gap-y-0 md:flex-row md:flex-1 md:justify-between mx-auto md:max-w-[80%] px-4 md:px-0 ">
            <div className="flex flex-1 gap-x-4 items-center">
              <div className="flex flex-col leading-none font-medium">
                <div className="flex justify-center">
                  <img src="head-track.svg" alt="Head Track logo" className="w-6/12 lg:w-8/12"/>
                </div>
                {/* <p className="text-gray-400 text-sm ml-4 mt-2">{serviceVersion}</p> */}
              </div>

            </div>
            <div className="md:gap-y-0 gap-y-4 flex-col md:flex-row gap-x-4 flex justify-center items-center">

            {isAdmin && (
                  <div className="flex md:mr-16 justify-center">
                    <ButtonInput
                        label={"Add Patient"}
                        isSubmit={false}
                        onClick={() => setAddPatientOpen(true)}
                        Icon={PlusIcon}
                        classes={"ml-4"}
                    />
                  </div>
              )}

              {!loading && (
                  <div className="flex flex-col leading-tight font-medium text-right">
                    {/* Users name */}
                    <p className="">{user?.displayName}</p>
                    {/* Users ID? */}
                    <p className="text-coag-blue-500"></p>
                  </div>
              )}

              {user && (
                  <ButtonInput
                      label={"Logout"}
                      isSubmit={false}
                      onClick={() => handleLogout()}
                      isSolid={false}
                      classes={""}
                  />
              )}

              {!user && !isAdmin && (
                <PillButton text='Nurses Login' onClick={() => {
                  navigate("/login")
                }} classes={'m-3'}/>
              )}

            </div>
          </div>
        </div>
      </>
  );
}
