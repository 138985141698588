import {Fragment, useEffect, useState} from "react";

import {DateTime} from "luxon";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import PatientStatusEnum from "../../constants/PatientStatusEnum";
import classNames from "../../utils/classNames";
import Pagination from "../Tables/Pagination";

const colHelper = createColumnHelper();

const cols = [
  colHelper.accessor((row) => `${row.firstname} ${row.lastname}`, {
    id: "name",
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("nhsNumber", {
    header: "NHS #",
    sortingFn: "auto",
  }),
  colHelper.accessor((row) => `${PatientStatusEnum[row.currentStatus]}`, {
    id: "status",
    header: "Status",
    cell: (info) => (
        <span className={classNames(info.getValue().includes("Declined")) ? "font-bold" : ""}>
        {info.getValue()}
      </span>
    ),
    sortingFn: "auto",
  }),
  colHelper.accessor("injuryDate", {
    header: "Injured On",
    sortingFn: "auto",
    cell: (info) => (
        <span>
        {info.getValue() !== null
            ? DateTime.fromISO(info.getValue())
                .setLocale("en-gb")
                .toLocaleString(DateTime.DATE_SHORT)
            : "Not Set"}
      </span>
    ),
  }),
  colHelper.accessor("resumeOn", {
    header: "Resumes On",
    sortingFn: "auto",
    cell: (info) => (
        <span>
        {info.getValue() !== null
            ? DateTime.fromISO(info.getValue())
                .setLocale("en-gb")
                .toLocaleString(DateTime.DATE_SHORT)
            : "Not Set"}
      </span>
    ),
  }),
  colHelper.accessor("lastContact", {
    header: "Last Contact",
    sortingFn: "auto",
    cell: (info) => (
        <span>
        {info.getValue() !== null
            ? DateTime.fromISO(info.getValue())
                .setLocale("en-gb")
                .toLocaleString(DateTime.DATE_SHORT)
            : "Not Set"}
      </span>
    ),
  }),
  colHelper.accessor("frailtyScore", {
    header: "Frailty",
    sortingFn: "auto",
  }),
];

export default function PatientTable({
                                       patients,
                                       onRowClick,
                                       isBlue = false,
                                       pagination = true,
                                       showSearchBox = true,
                                       showRestartedToggle = true,
                                     }) {
  const [sorting, setSorting] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [restartedHidden, setRestartedHidden] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState(patients);

  // When filter options change, reproduce the list of patients to display
  // This is defined within this useEffect() method
  useEffect(() => {
    let filtered = patients;

    if (searchQuery !== "") {
      filtered = filtered.filter(
          (patient) =>
              `${patient.firstname} ${patient.lastname}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
              patient.nhsNumber.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (restartedHidden) {
      filtered = filtered.filter((patient) => patient.currentStatus !== 2);
    }

    setFilteredPatients(filtered);
  }, [searchQuery, restartedHidden, patients]);


  const table = useReactTable({
    data: filteredPatients,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    if (!pagination) {
      table.setPageSize(10000);
    } else {
      table.setPageSize(50);
    }
  }, [pagination, table]);

  return (
      <>
        {showSearchBox && showRestartedToggle &&
            <div className="flex items-center justify-between">
              <div className="flex space-x-3">
                <input
                    type="checkbox"
                    id="hide-restarted"
                    checked={restartedHidden}
                    onChange={(e) => setRestartedHidden(e.target.checked)}
                />
                <label for="hide-restarted">
                  <p className="text-xs">Hide patients who have restarted their medication</p>
                </label>
              </div>
              <input
                  type="text"
                  className="px-3"
                  placeholder="Search patients"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
        }
        {showSearchBox && !showRestartedToggle &&
            <div className="flex items-center justify-end">
              <input
                  type="text"
                  className="px-3"
                  placeholder="Search patients"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
        }
        {!showSearchBox && showRestartedToggle &&
            <div className="flex items-center justify-left">
              <div className="flex space-x-3">
                <input
                    type="checkbox"
                    id="hide-restarted"
                    checked={restartedHidden}
                    onChange={(e) => setRestartedHidden(e.target.checked)}
                />
                <label for="hide-restarted">
                  <p className="text-xs">Hide patients who have restarted their medication</p>
                </label>
              </div>
            </div>
        }
        <div
            className={classNames(isBlue ? "text-white" : "", "mt-4 overflow-auto")}
        >
          <table className="min-w-full overflow-scroll divide-y divide-gray-300">
            <thead className="border-b-4">
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="bg-gp-blue-50">
                  {headerGroup.headers.map((header, i) => (
                      <th
                          key={header.id}
                          scope="col"
                          className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                      >
                        {header.isPlaceholder ? null : (
                            <div
                                {...{
                                  className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "",
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                            >
                              {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                              )}
                              {{
                                asc: " ▲",
                                desc: " ▼",
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                        )}
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            <tbody className="divide-y-2">
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                  <Fragment key={i}>
                    <tr
                        key={row.id}
                        onClick={() => onRowClick(row.original)}
                        className={classNames(
                            isBlue ? "hover:bg-coag-blue-600" : "hover:bg-gray-100",
                            " hover:cursor-pointer"
                        )} //handleClassNames(row.original),
                    >
                      {rowCells.map((cell) => (
                          <td key={cell.id} className="px-3 py-3 text-sm">
                            {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                            )}
                          </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                        <tr>
                          {/* 2nd row is a custom 1 cell row */}
                          <td colSpan={row.getVisibleCells().length}></td>
                        </tr>
                    )}
                  </Fragment>
              );
            })}
            </tbody>
          </table>
          {pagination && <Pagination table={table}/>}
        </div>
      </>
  );
}
