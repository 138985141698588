import PromptCard from "../../components/Card/PromptCard";
import {PromptButton} from "../../components/Inputs/PromptButton";
import {useNavigate, useParams} from "react-router";

export default function ConfirmResume() {
  const {token, source} = useParams();
  const navigate = useNavigate();
  // const { user } = useAuth();
  // const { patient } = usePatient(user?.uid);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (!patient.isLoading) {
  //     setIsLoading(false);
  //   }
  // }, [patient.isLoading, patient.data, user?.uid]);

  // if (isLoading) {
  //   return <LoadingWheel />;
  // }

  return (
      // <PromptCard
      //   title={`Time to restart your ${patient.data.medication.name} with ${patient.data.dosage}.`}
      //   preTitle={`Hello ${user?.displayName},`}
      // >
      //   <p className="text-lg">
      //     You had your operation {getResumeInDays(patient.data.injuryDate)} at NHS
      //     Derriford Hospital, where we paused your {patient.data.dosage} of{" "}
      //     {patient.data.medication.name}.
      //   </p>
      //   <p className="text-lg">
      //     Please confirm below that you have restarted{" "}
      //     {patient.data.medication.name} today -{" "}
      //     {DateTime.fromISO(patient.data.resumeOn)
      //       .setLocale("en-gb")
      //       .toLocaleString(DateTime.DATE_SHORT)}{" "}
      //     - with {patient.data.dosage}.
      //   </p>
      //   <div className="flex flex-col space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
      //     <PromptButton
      //       label="Confirm"
      //       onClick={() => navigate("../frailty")}
      //       filled
      //     />
      //     <PromptButton
      //       label="I have a problem"
      //       onClick={() => navigate("../report-issue")}
      //     />
      //   </div>
      // </PromptCard>

      <PromptCard
          // 0 == Patient  -:-  1 == NOK
          title={source === "0" ? "It's time to restart your medication." : "It's time for your next of kin to restart their medication."}
          preTitle={`Hello, thank you for responding to HeadTrack!`}
      >
        <hr></hr>
        <p className="text-lg">
          {source === "0" ? "You recently had your operation at NHS Derriford Hospital, where we paused your medication." : "Your next of kin recently had their operation at NHS Derriford Hospital, where we paused their medication."}
        </p>
        <p className="text-lg">
          {source === "0" ? "Please confirm you have restarted it, or let us know if something is wrong." : "Please confirm they have restarted it, or let us know if something is wrong."}
        </p>
        <div className="flex flex-col space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
          <PromptButton
              label="Confirm"
              onClick={() => navigate(`../frailty/${token}/${source}`)}
              filled
          />
          <PromptButton
              label="There is a problem"
              onClick={() => navigate(`../report-issue/${token}/${source}`)}
          />
        </div>
      </PromptCard>
  );
}
