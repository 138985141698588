import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";

/**
 * Wrapper for creating flyouts
 * @param {boolean} open flyout open or closed (shown or hidden)
 * @param {Function} setOpen set the visability of the flyout
 * @param {string} title title to place at the top of the flyout
 * @param {JSX.Element[]} children content to render in the flyout
 * @param {JSX.Element} rawContent ignores all built in styling and just places elements in the flyout
 */
export default function Flyout({
                                 open,
                                 setOpen,
                                 title,
                                 subtitle,
                                 children,
                                 rawContent = false,
                                 backdropBlur = true,
                               }) {
  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
            as="div"
            className="fixed inset-0 overflow-hidden z-40"
            onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            {backdropBlur ? (
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="backdrop-blur-none bg-transparent"
                    enterTo="backdrop-blur-sm bg-gray-500/20"
                    leave="transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="backdrop-blur-sm bg-gray-500/20"
                    leaveTo="backdrop-blur-none bg-transparent"
                >
                  <Dialog.Overlay className="transition absolute inset-0"/>
                </Transition.Child>
            ) : (
                <Dialog.Overlay className="transition absolute inset-0"/>
            )}
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-full flex-col overflow-x-hidden bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-8">
                      {rawContent ? (
                          <>
                            <div className="flex h-7 justify-end items-center">
                              <button
                                  type="button"
                                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                  onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                              </button>
                            </div>
                            {rawContent}
                          </>
                      ) : (
                          <>
                            <div className="mt-2 flex items-start justify-between">
                              <div className="flex flex-col">
                                <Dialog.Title className="text-4xl font-semibold text-gray-900">
                                  {title}
                                </Dialog.Title>
                                {subtitle && (
                                    <Dialog.Description
                                        className="mt-2 text-md font-semibold text-gray-900">
                                      {subtitle}
                                    </Dialog.Description>
                                )}
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                    onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close</span>
                                  <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="mt-6">{children}</div>
                          </>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  );
}
