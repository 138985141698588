import {useEffect, useState} from "react";

import {Navigate, Outlet, useLocation} from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// Allowed roles will be object with bools, e.g:
// allowedRoles = {
//  isAdmin: true,
// }

export default function RequireAuth({allowedRoles, redirect = "/"}) {
  const {auth, user, loading} = useAuth();
  const location = useLocation();

  const [userClaims, setUserClaims] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      user?.getIdTokenResult().then((idTokenResult) => {
        // console.log(idTokenResult.claims)
        setUserClaims(idTokenResult.claims);
        setIsLoading(false);
      });
    }
  }, [user, loading])

  return (
      (!isLoading && userClaims) && (
          //Checks that the bool in the allowedRoles object match the userClaims object
          Object.keys(allowedRoles).some(role => allowedRoles[role] && userClaims[role]) ?
              //Authorised
              <Outlet/> :

              //Unauthorised or no user
              (
                  // Checks if user is unauthorized, or not logged in
                  user ? <Navigate to={redirect} state={{from: location}} replace/> :
                      <Navigate to="/login" state={{from: location}} replace/>
              )
      )
  )

}