import {useRouteError} from "react-router-dom";
import Exception from "./Exception";
import NotFound from "./NotFound";

export default function ErrorBoundry() {
  const err = useRouteError();

  if (process.env.NODE_ENV === "development") {
    console.error("Error boundry caught:", err);
  }

  if (err["status"] === 404) {
    return <NotFound/>;
  } else {
    return <Exception/>;
  }
}
