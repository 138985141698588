import React from 'react';
import classNames from '../../utils/classNames';
import {feedbackEmail} from '../../config';

const FeedbackBanner = ({classes}) => {

  // Explicit banner styling
  const bannerStyle = classNames(
      `${classes}`,
      'bg-feedback-banner p-6 rounded-md',
      'text-md md:text-lg font-normal',
      'flex items-center'
  );

  return (
      <div className={bannerStyle}>
        <div
            className='bg-coag-blue-500 px-4 h-8 rounded-sm text-sm flex items-center justify-center text-white font-bold'>BETA
        </div>
        {/* <p className='pl-6'>This is a new service. Your <a href={`mailto:${feedbackEmail}`}>feedback</a> will help us improve it.</p> */}
        <p className='pl-6'>This is a new service. Your feedback will help us improve it.</p>
      </div>
  );
};

export default FeedbackBanner;
