import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";
import {staleTime} from "../constants/reactQueryConstants";

export default function usePatients() {
  const queryClient = useQueryClient();
  const {get, post, put, del} = useApiHelper();

  const patients = useQuery(
      ["patients"],
      () => get(`/patient`).then((res) => res.data),
      {
        staleTime,
        refetchInterval: 1000 * 60,
      }
  );

  const create = useMutation((newPatient) => post(`/patient`, newPatient), {
    onSuccess: () => queryClient.invalidateQueries(["patients"]),
  });

  const update = useMutation((updatedPatient) => put(`/patient`, updatedPatient), {
    onSuccess: () => queryClient.invalidateQueries(["patients"]),
  });

  const remove = useMutation((patientId) => del(`/patient/${patientId}`), {
    onSuccess: (_, patientId) => {
      queryClient.setQueryData(["patients"], (currentPatients) =>
          currentPatients.filter((patient) => patient.id !== patientId)
      );
    },
  });

  return {
    patients,
    create,
    update,
    remove,
  };
}
