import {useEffect, useState} from "react";

import {DateTime} from "luxon";
import {useForm} from "react-hook-form";

import {TextInput} from "../Inputs/TextInput";
import {DateInput} from "../Inputs/DateInput";
import SwitchInput from "../Inputs/SwitchInput";
import SelectInput from "../Inputs/SelectInput";
import {FrailtyScore} from "../../constants/FrailtyScore";
import useMedications from "./../../data/useMedications";
import useInjuryTypes from "./../../data/useInjuryTypes";
import {ButtonFormInput} from "../Inputs/ButtonFormInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import PatientStatusEnum from "../../constants/PatientStatusEnum";
import useDefaultCRUDHandlers from "./../../hooks/useDefaultCRUDHandlers";
import usePatient from "../../data/usePatient";
import {EmergencyContactRow} from "./../EmergencyContact/EmergencyContactRow";
import Flyout from "./../../layouts/Flyout";
import EmergencyContactEdit from "../EmergencyContact/EmergencyContactEdit";
import EmergencyContactAdd from "../EmergencyContact/EmergencyContactAdd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons";

export default function EditPatient({patient: editPatient, onSuccess, setFrailtyOpen}) {
  const {medications} = useMedications();
  const {injuryTypes} = useInjuryTypes();
  const {patient, update} = usePatient(editPatient.id);
  const {saveHandlers} = useDefaultCRUDHandlers("Patient");
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: {
      nokComs: false,
      smsComs: false,
      emailComs: false,
      currentStatus: 0,
    },
  });
  const [addNokOpen, setAddNokOpen] = useState(false);
  const [editNokOpen, setEditNokOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedNok, setSelectedNok] = useState(undefined);

  const updatePatient = (data) => {
    update.mutate(data, {
      onSuccess: (res) => {
        if (res.success) {
          saveHandlers.onSuccess();
          onSuccess();
        } else {
          setErrorMessage(res.message);
        }
      },
      onError: () => {
        setErrorMessage("Something went wrong! please check all fields.")
      }
    });
  };

  useEffect(() => {
    if (editPatient) {

      reset({
        firstname: editPatient.firstname,
        lastname: editPatient.lastname,
        nhsNumber: editPatient.nhsNumber,
        phone: editPatient.phone,
        email: editPatient.email,
        dosage: editPatient.dosage,
        injuryDate: DateTime.fromISO(editPatient.injuryDate).toFormat(
            "yyyy-MM-dd"
        ),
        resumeOn: DateTime.fromISO(editPatient.resumeOn).toFormat("yyyy-MM-dd"),
        frailtyScore: editPatient.frailtyScore,
        currentStatus: editPatient.currentStatus,
        canNOK: editPatient.canNOK,
        canSms: editPatient.canSms,
        canEmail: editPatient.canEmail,
        injuryTypeId: editPatient.injuryTypeId,
        medicationId: editPatient.medicationId,
      });
    }
  }, [editPatient, reset]);

  if (patient.isLoading || medications.isLoading || injuryTypes.isLoading) {
    return <LoadingWheel/>;
  } else {
    return (
        <>
          <Flyout
              open={editNokOpen}
              setOpen={setEditNokOpen}
              title={
                selectedNok
                    ? `${selectedNok.firstname} ${selectedNok.lastname}`
                    : ""
              }
              backdropBlur={false}
          >
            <EmergencyContactEdit
                contact={selectedNok}
                onSuccess={() => setEditNokOpen(false)}
            />
          </Flyout>
          <Flyout
              open={addNokOpen}
              setOpen={setAddNokOpen}
              title="Add Next of Kin"
              backdropBlur={false}
          >
            <EmergencyContactAdd
                onSuccess={() => setAddNokOpen(false)}
                patientId={editPatient.id}
            />
          </Flyout>
          <form onSubmit={handleSubmit(updatePatient)}>
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                  label="First Name:"
                  register={register}
                  required
                  registerName="firstname"
              />
              <TextInput
                  label="Last Name:"
                  register={register}
                  required
                  registerName="lastname"
              />

              <TextInput
                  label="NHS#:"
                  register={register}
                  required
                  registerName="nhsNumber"
              />
              <TextInput
                  label="Phone:"
                  register={register}
                  required
                  registerName="phone"
              />

              <TextInput
                  label="Email:"
                  register={register}
                  required
                  registerName="email"
                  cols={2}
              />

              <SelectInput
                  label="Injury Type:"
                  register={register}
                  registerName="injuryTypeId"
                  options={injuryTypes.data.map((inType) => ({
                    value: inType.id,
                    label: inType.name,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />
              <SelectInput
                  label="Medication:"
                  register={register}
                  registerName="medicationId"
                  options={medications.data.map((med) => ({
                    value: med.id,
                    label: med.name,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />

              <TextInput
                  label="Dosage:"
                  register={register}
                  registerName="dosage"
                  cols={2}
              />

              <DateInput
                  label="Injured On:"
                  register={register}
                  registerName="injuryDate"
                  cols={1}
              />
              <DateInput
                  label="Resume On:"
                  register={register}
                  registerName="resumeOn"
                  cols={1}
              />
              <div style={{position: "relative"}}>
                <FontAwesomeIcon className={"rounded-full p-1 hover:ring-4 hover:ring-blue-500"} icon={faCircleQuestion}
                                 style={{position: "absolute", right: 0, zIndex: 1, transform: "translate(25%,-25%)"}}
                                 onClick={x => setFrailtyOpen(true)} size={"lg"}/>
                <SelectInput
                    label="Frailty:"
                    register={register}
                    registerName="frailtyScore"
                    setValue={setValue}
                    cols={1}
                    watch={watch}
                    classes="text-black"
                    options={Object.values(FrailtyScore).map((score, i) => ({
                      value: i + 1,
                      label: `${i + 1} - ${FrailtyScore[i + 1]}`,
                    }))}
                />
              </div>
              <SelectInput
                  label="Current Status:"
                  register={register}
                  registerName="currentStatus"
                  options={Object.values(PatientStatusEnum).map((status, i) => ({
                    value: i,
                    label: status,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />
            </div>

            <div className="mt-4">
              <p className="ml-4 font-semibold">Contact Preferences</p>

              <div className="mt-8 grid grid-cols-1 space-y-12 md:space-y-0 md:grid-cols-3">
                <SwitchInput
                    label="NOK"
                    register={register}
                    registerName="canNOK"
                    watch={watch}
                    setValue={setValue}
                />
                <SwitchInput
                    label="SMS"
                    register={register}
                    registerName="canSms"
                    watch={watch}
                    setValue={setValue}
                />
                <SwitchInput
                    label="Email"
                    register={register}
                    registerName="canEmail"
                    watch={watch}
                    setValue={setValue}
                />
              </div>
            </div>

            <div className="mt-8">
              <p className="ml-4 mb-4 font-semibold">Emergency Contacts/NOK</p>
              <div className="flex flex-col space-y-2 mb-4">
                {patient.data.nextOfKin.map((nok) => (
                    <EmergencyContactRow
                        contact={nok}
                        onRowClick={() => {
                          console.log("clicked");
                          setSelectedNok(nok);
                          setEditNokOpen(true);
                        }}
                    />
                ))}
              </div>
              <ButtonFormInput
                  label="Add Next of Kin"
                  isSubmit={false}
                  isBlue={false}
                  onClick={() => setAddNokOpen(true)}
                  classes="!py-4 w-full"
              />
            </div>

            <p className="font-bold text-center p-1 text-coag-red">{errorMessage}</p>

            <div className="mt-4 grid grid-cols-1">
              <ButtonFormInput
                  label="Save Patient"
                  isSubmit={true}
                  isBlue={true}
                  onClick={() => {
                  }}
              />
            </div>

            {Object.keys(errors).length > 0 && (
                <div className="mt-4 px-4 text-right text-red-500">
                  <p>Please fill in all required fields!</p>
                </div>
            )}
          </form>
        </>
    );
  }
}
