// daysSince.js
// =================
// Can be used to calculate the number of days since or until a given date

import {DateTime} from "luxon";

export default function getResumeInDays(resumeDate) {

  // Resumtion date and current date collected and converted to DateTime objects
  // To squash a time-related bug, we use the startOf() method to set the time to 00:00:00
  let resume = DateTime.fromISO(resumeDate).startOf('day');
  let today = DateTime.now().startOf('day');

  // Difference between the two dates in days
  let diff = Math.floor(today.diff(resume, "days").toObject().days);

  if (diff < 0) {
    // If the difference is negative, the resume date is assumed to be in the future
    return `in ${Math.abs(diff).toFixed(0)} days`;
  } else if (diff === 0) {
    // If the difference is 0, the resume date is assumed to be today
    return "Today";
  } else if (diff === 1) {
    // If the difference is 1, the resume date is assumed to be yesterday
    return "Yesterday";
  } else {
    // If the difference is positive, the resume date is assumed to be in the past
    return `${Math.abs(diff).toFixed(0)} days ago`;
  }
};