import {useEffect} from "react";

import {Outlet, useLocation, useNavigate} from "react-router-dom";

import Topbar from "../components/Navbar/Topbar";
import LoadingWheel from "../components/shared/LoadingWheel";
import {usePageNameContext} from "../contexts/PageTitleContext";
import useAuth from "../hooks/useAuth";

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    pageInfo: {name, desc},
  } = usePageNameContext();
  const {auth, user, loading} = useAuth();

  const checkPatient = () => {
    auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
      if (!idTokenResult.claims.isAdmin) {
        navigate("/prompt", {state: {from: location}, replace: true});
      } else {
        navigate("/home", {state: {from: location}, replace: true});
      }
    });
  }

  useEffect(() => {
    if (!loading) {
      console.log("user", user);
      if (!user) navigate("/", {state: {from: location}, replace: true});

      //Only do the redirect on index
      if (location.pathname === "/") {
        checkPatient();
      }
    }
  }, [user, loading, navigate]);

  return (
      <>
        <div>
          <div className="flex flex-1 flex-col">
            <Topbar/>
            <main>
              <div className="py-6">
                <div className="mx-auto md:max-w-[80%] px-4 md:px-0">
                  {loading ? <LoadingWheel/> : <Outlet/>}
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
  );
}
