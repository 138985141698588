import {useEffect} from "react";

import {useForm} from "react-hook-form";

import {TextInput} from "../Inputs/TextInput";
import SwitchInput from "../Inputs/SwitchInput";
import {ButtonFormInput} from "../Inputs/ButtonFormInput";
import useDefaultCRUDHandlers from "./../../hooks/useDefaultCRUDHandlers";
import useNOK from "./../../data/useNOK";

export default function EmergencyContactEdit({contact, onSuccess}) {
  const {update} = useNOK();
  const {saveHandlers} = useDefaultCRUDHandlers("Next of kin");
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm();

  const updateContact = (data) => {
    update.mutate(
        {...contact, ...data},
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            onSuccess();
          },
          onError: saveHandlers.onError,
        }
    );
  };

  useEffect(() => {
    reset({
      firstname: contact.firstname,
      lastname: contact.lastname,
      phone: contact.phone,
      email: contact.email,
      relation: contact.relation,
      canEmail: contact.canEmail,
      canSms: contact.canSms,
    });
  }, [contact, reset]);

  return (
      <form onSubmit={handleSubmit(updateContact)}>
        <div className="grid grid-cols-2 gap-4">
          <TextInput
              label="First Name:"
              register={register}
              required
              registerName="firstname"
          />
          <TextInput
              label="Last Name:"
              register={register}
              required
              registerName="lastname"
          />

          <TextInput
              label="Phone:"
              register={register}
              required
              registerName="phone"
          />
          <TextInput
              label="Relation:"
              register={register}
              required
              registerName="relation"
          />

          <TextInput
              label="Email:"
              register={register}
              required
              registerName="email"
              cols={2}
          />
        </div>

        <div className="mt-4">
          <p className="ml-4 font-semibold">Contact Preferences</p>

          <div className="mt-8 grid grid-cols-1 space-y-4 lg:space-y-0 lg:grid-cols-3">
            <SwitchInput
                label="SMS"
                register={register}
                registerName="canSms"
                watch={watch}
                setValue={setValue}
            />
            <SwitchInput
                label="Email"
                register={register}
                registerName="canEmail"
                watch={watch}
                setValue={setValue}
            />
          </div>
        </div>

        <div className="mt-16 grid grid-cols-1">
          <ButtonFormInput
              label="Save Contact"
              isSubmit={true}
              isBlue={true}
              onClick={() => {
              }}
          />
        </div>

        {Object.keys(errors).length > 0 && (
            <div className="mt-4 px-4 text-right text-red-500">
              <p>Please fill in all required fields!</p>
            </div>
        )}
      </form>
  );
}
