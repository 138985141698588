export const FrailtyScore = {
  1: "Very Fit",
  2: "Fit",
  3: "Managing Well",
  4: "Living with Very Mild Frailty",
  5: "Living with Mild Frailty",
  6: "Living with Moderate Frailty",
  7: "Living with Severe Frailty",
  8: "Living with Very Severe Frailty",
  9: "Terminally Ill"
}
