import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";
import {staleTime} from "../constants/reactQueryConstants";

export default function useInjuryTypes() {
  const queryClient = useQueryClient();
  const {get, post, put, del} = useApiHelper();

  const injuryTypes = useQuery(
      ["injurytype"],
      () => get(`/injurytype`).then((res) => res.data),
      {
        staleTime,
      }
  );

  const create = useMutation(
      (newInjuryType) => post(`/injurytype`, newInjuryType),
      {
        onSuccess: () => queryClient.invalidateQueries(["injurytype"]),
      }
  );

  const update = useMutation(
      (updatedInjuryType) =>
          put(`/injurytype/${updatedInjuryType.id}`, updatedInjuryType),
      {
        onSuccess: () => queryClient.invalidateQueries(["injurytype"]),
      }
  );

  const remove = useMutation(
      (injuryTypeId) => del(`/injurytype/${injuryTypeId}`),
      {
        onSuccess: () => queryClient.invalidateQueries(["injurytype"]),
      }
  );

  return {
    injuryTypes,
    create,
    update,
    remove,
  };
}
