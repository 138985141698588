import PromptCard from "../../components/Card/PromptCard";

export default function IssueSubmitted() {
  // const { user } = useAuth();
  // const { patient } = usePatient(user?.uid);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (!patient.isLoading) {
  //     setIsLoading(false);
  //   }
  // }, [patient.isLoading, patient.data, user?.uid]);

  // if (isLoading) {
  //   return <LoadingWheel />;
  // }

  return (
      <PromptCard title="Submitted">
        <p className="text-lg">
          Thank you for submitting your issue.
          If you or someone you know are feeling
          unwell, you can call 111 or 999.
        </p>
      </PromptCard>
  );
}
