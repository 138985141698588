import {useAuthState} from "react-firebase-hooks/auth";
import {resourceAPIURL} from "../config";
import {auth} from "../services/Firebase";

export default function useApiHelper(config = {apiUrl: undefined, headers: {}, options: {}}) {
  const [user] = useAuthState(auth);
  const baseUrl = config.apiUrl || `${resourceAPIURL}/api`;

  const _getBearer = async () => {
    try {
      const id = await user?.getIdTokenResult();
      if (id) {
        // console.log(id);
        return {Authorization: `Bearer ${id.token}`};
      } else {
        return {Authorization: `Bearer`};
      }
    } catch (err) {
      console.error(err);
      return {Authorization: `Bearer`};
    }
  };

  const _getReqOpts = async () => {
    const reqOpts = {
      headers: {
        "Content-Type": "application/json",
        ...(await _getBearer()),
      },
      ...config.options,
    };

    return reqOpts;
  };

  async function _handleResponse(res) {
    if (!res.ok) {
      let message = `HTTP Error: ${res.status}`;

      if (res.status === 400) {
        try {
          let json = await res.json();
          if (json.message) {
            message = json.message;
          }
        } catch {
        }
      }

      throw new Error(message);
    }

    return await res.json();
  }

  async function get(path) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      method: "GET",
    });

    return await _handleResponse(rawRes);
  }

  async function put(path, body) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "PUT",
    });

    let res = await _handleResponse(rawRes);

    return res;
  }

  async function patch(path, body) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "PATCH",
    });

    let res = await _handleResponse(rawRes);

    return res;
  }

  async function post(path, body) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "POST",
    });

    let res = await _handleResponse(rawRes);

    return res;
  }

  async function del(path, body) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "DELETE",
    });

    let res = await _handleResponse(rawRes);

    return res;
  }

  return {
    get,
    put,
    patch,
    post,
    del,
  };
}
