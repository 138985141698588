import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";
import {staleTime} from "../constants/reactQueryConstants";

export default function useMedications() {
  const queryClient = useQueryClient();
  const {get, post, put, del} = useApiHelper();

  const medications = useQuery(
      ["medication"],
      () => get(`/medication`).then((res) => res.data),
      {
        staleTime,
      }
  );

  const create = useMutation(
      (newMedication) => post(`/medication`, newMedication),
      {
        onSuccess: () => queryClient.invalidateQueries(["medication"]),
      }
  );

  const update = useMutation(
      (updatedMedication) =>
          put(`/medication/${updatedMedication.id}`, updatedMedication),
      {
        onSuccess: () => queryClient.invalidateQueries(["medication"]),
      }
  );

  const remove = useMutation(
      (medicationId) => del(`/medication/${medicationId}`),
      {
        onSuccess: () => queryClient.invalidateQueries(["medication"]),
      }
  );

  return {
    medications,
    create,
    update,
    remove,
  };
}
