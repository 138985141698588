import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

export default function ErrorBanner({
                                      title = "An Error Occoured",
                                      showSupport = true,
                                      message,
                                    }) {
  return (
      <div className="flex items-center space-x-6 p-4 rounded-lg bg-coag-lightred text-coag-red">
        <ExclamationCircleIcon className="w-16 h-16"/>
        <div>
          <h4 className="text-xl font-bold">{title}</h4>
          {message && (
              <p>
                {message}
                {showSupport && ", please contact support if the issue persists"}
              </p>
          )}
        </div>
      </div>
  );
}
