import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";
import {staleTime} from "../constants/reactQueryConstants";

export default function usePatient(patientId, seeHidden) {
  const queryClient = useQueryClient();
  const {get, post, put, del} = useApiHelper();

  const patient = useQuery(
      ["patient", patientId, "seeHidden", seeHidden],
      () => get(seeHidden ? `/patient/${patientId}?withHidden=true` : `/patient/${patientId}`).then((res) => res.data),
      {
        staleTime,
      }
  );

  const toggleHidden = useMutation(
      (see) => {
        queryClient.invalidateQueries(["seeHidden", see]);
      }
  )

  const update = useMutation(
      (updatedPatient) => put(`/patient/${patientId}`, updatedPatient),
      {
        onSuccess: (res) => {
          if (res.success) {
            queryClient.invalidateQueries(["patients"]);
            queryClient.invalidateQueries(["patient", patientId]);
          }
        },
      }
  );

  const partialUpdate = useMutation(
      (partial) => {
        put('/patient', partial);
        console.log("usePatient", partial);
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["patients"]);
          queryClient.invalidateQueries(["patient", patientId]);
        },
      }
  )

  const sendPrompt = useMutation(
      () => post(`/patient/${patientId}/resend-prompt`),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["patient", patientId]);
        },
      }
  );

  const addFreeformNote = useMutation(
      (note) => post(`/patient/${patientId}/note`, note),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["patient", patientId]);
        },
      }
  )

  const deleteNote = useMutation(
      (noteId) => del(`/patient/note/${noteId}`),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["patient", patientId]);
        },
      }
  )

  const restoreNote = useMutation(
      (noteId) => put(`/patient/note/${noteId}`),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["patient", patientId]);
        },
      }
  )

  return {
    patient,
    update,
    partialUpdate,
    sendPrompt,
    addFreeformNote,
    deleteNote,
    toggleHidden,
    restoreNote
  };
}
