import {useNavigate} from "react-router";

import {useAuthState, useSignOut} from "react-firebase-hooks/auth";

import {auth} from "../services/Firebase";

export default function useAuth() {
  const navigate = useNavigate();
  const [signOut] = useSignOut(auth);
  const [user, loading] = useAuthState(auth);

  const handleLogout = async (redirect = true) => {
    try {
      const logoutRes = await signOut();
      if (logoutRes && redirect) {
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {auth, user, loading, handleLogout};
}