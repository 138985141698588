import { Outlet } from "react-router";
import LandingInfoBanner from "../components/shared/LandingInfoBanner";

export default function PromptLayout() {
  return (
    <>
      <Outlet />
      <div className="max-w-7xl mx-auto mt-8">
        <LandingInfoBanner rounded={false} />
      </div>
    </>
  );
}
