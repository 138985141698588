import {useEffect, useState} from "react";

import {useForm} from "react-hook-form";

import {ButtonFormInput} from "../../components/Inputs/ButtonFormInput";
import {TextInput} from "../../components/Inputs/TextInput";
import useLogin from "../../data/useLogin";
import useNotification from "../../hooks/useNotifications";
import Topbar from "../../components/Navbar/Topbar";
import LoadingWheel from "../../components/shared/LoadingWheel";
import useAuth from "../../hooks/useAuth";

export default function ConfirmEmail() {
  const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {email: ""}});
  const {addNotification} = useNotification();
  const {loading} = useAuth();
  const {login} = useLogin();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {

  }, [completed])

  const onSubmit = (data) => {

    login.mutate(data, {
      onSuccess: () => {
        // addNotification({
        //   variant: "success",
        //   primaryText: "Email Confirmed"
        // });
        setCompleted(true);

        //save user email to local storage
        window.localStorage.setItem('emailForSignIn', data.email);
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Unable to Confirm Email"
        });
      }
    });
  }

  return (
      <div>
        <div className="flex flex-1 flex-col">
          <Topbar/>
          <main>
            <div className="py-6">
              <div className="mx-auto md:max-w-[80%] px-4 md:px-0">
                {loading ? <LoadingWheel/> : (

                    !completed ? (
                        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 max-w-xl mx-auto space-y-6">
                          <h1 className="text-3xl font-bold text-center">Please Confirm Email Address</h1>
                          <TextInput
                              register={register}
                              registerName="email"
                              label="Email Address:"
                              required
                          />

                          <ButtonFormInput label="Confirm" isSubmit={true} onClick={() => {
                          }} isBlue classes="w-full"/>

                        </form>
                    ) : (
                        <div className="mt-4 max-w-xl mx-auto space-y-6">
                          <h1 className="text-3xl font-bold text-center">Email Confirmed!</h1>
                          <p className="text-center">Please follow the link in the email we have just sent
                            you to login.</p>
                        </div>
                    )
                )}
              </div>
            </div>
          </main>
        </div>
      </div>


  )

}