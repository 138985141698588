import {useCallback} from "react";

import {useNotifier} from "react-headless-notifier";

import {
  ErrorNotification,
  InfoNotification,
  SuccessNotification,
  WarningNotification
} from './../components/shared/NotificationTypes';

export const NotificationVariant = {
  success: "success",
  info: "info",
  warn: "warn",
  error: "error",
};

export default function useNotification() {
  const {notify} = useNotifier();

  const addNotification = useCallback((notification, config) => {
    switch (notification.variant) {
      case NotificationVariant.success:
        return notify(<SuccessNotification {...notification} />, config);
      case NotificationVariant.info:
        return notify(<InfoNotification {...notification} />, config);
      case NotificationVariant.warn:
        return notify(<WarningNotification {...notification} />, config);
      case NotificationVariant.error:
        return notify(<ErrorNotification {...notification} />, config);
      default:
        return notify(<InfoNotification {...notification} />, config);
    }
  }, []);

  return {
    addNotification,
  };
}
