import {Switch} from "@headlessui/react";
import classNames from "../../utils/classNames";

export default function SwitchInput({label, register, registerName, setValue, watch}) {

  const switchValue = watch(registerName);

  return (
      <Switch.Group as="div" className="left-16 relative w-full">
      <span className="absolute z-10 left-4 pointer-events-none">
        <Switch.Label as="span"
                      className={classNames(switchValue ? "text-white" : "text-black", "text-md font-semibold ")}
                      passive>
          {label}
        </Switch.Label>
      </span>
        <Switch
            {...register(registerName)}
            checked={switchValue}
            onChange={(e) => setValue(registerName, e)}
            className={classNames(
                switchValue ? "bg-coag-blue-500" : "bg-gray-200",
                "scale-[2.5] relative inline-flex flex-shrink-0 h-6 w-20 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
            )}
        >
        <span
            aria-hidden="true"
            className={classNames(
                switchValue ? "translate-x-14" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
        />
        </Switch>
      </Switch.Group>
  )
}