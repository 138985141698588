import {useState} from "react";

import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router";

import useAuth from "../../hooks/useAuth";
import usePatient from "../../data/usePatient";
import PromptCard from "../../components/Card/PromptCard";
import {FrailtyScore} from "../../constants/FrailtyScore";
import SelectInput from "../../components/Inputs/SelectInput";
import {PromptButton} from "../../components/Inputs/PromptButton";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import {TextInput} from "../../components/Inputs/TextInput";

export default function ReportIssue() {
  const {token, source} = useParams();
  const navigate = useNavigate();
  const {user} = useAuth();
  const {patient, partialUpdate} = usePatient(user?.uid);
  const {saveHandlers} = useDefaultCRUDHandlers("Details");
  const {register, handleSubmit, setValue, watch} = useForm({
    defaultValues: {
      frailtyScore: "1",
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = (data) => {
    partialUpdate.mutate(
        {...data, currentStatus: 3, token: token},
        {
          onSuccess: () => {
            navigate("../issue-submitted");
          },
          onError: () => {
            saveHandlers.onError();
          },
        }
    );
  };

  // useEffect(() => {
  //   if (!patient.isLoading) {
  //     setIsLoading(false);
  //   }
  // }, [patient.isLoading, patient.data, user?.uid]);

  // if (isLoading) {
  //   return <LoadingWheel />;
  // }

  return (
      <PromptCard title="We're sorry to hear that.">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-3">
            <p className="text-lg pl-2">{source === "0" ? "How are you feeling?" : "How are they feeling?"}</p>
            <SelectInput
                label="Frailty"
                register={register}
                registerName="frailtyScore"
                setValue={setValue}
                watch={watch}
                className="text-black"
                options={Object.keys(FrailtyScore).map((score) => ({
                  value: score,
                  label: `${score} - ${FrailtyScore[score]}`,
                }))}
            />
          </div>
          <div className="space-y-3">
            <p className="text-lg pl-2">Comments</p>
            <TextInput
                className="text-black"
                register={register}
                registerName="note"
            />
          </div>
          <PromptButton filled label="Confirm" isSubmit/>
          <p className="text-white">
            {source === "0" ? "When you submit this form, you are confirming you have NOT restarted your medication." : "When you submit this form, you are confirming your next of kin has NOT restarted their medication."}
          </p>
        </form>
      </PromptCard>
  );
}
