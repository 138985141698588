import {ButtonInput} from "../Inputs/ButtonInput"

export default function Pagination({table}) {

  return (
      <div className="flex justify-between items-center gap-2 py-4">

        <div className="pl-4">
          {table.getPreFilteredRowModel().rows.length} Total Rows
        </div>

        <div className="flex gap-2">
          <ButtonInput label={"<"} isSubmit={false} onClick={() => table.previousPage()}
                       disabled={!table.getCanPreviousPage()} classes="text-xl"
          />

          <ButtonInput label={">"} isSubmit={false} onClick={() => table.nextPage()}
                       disabled={!table.getCanNextPage()} classes="text-xl"
          />

          <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
          <span className="flex items-center gap-1">
          | Go to page:
          <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="border p-1 rounded w-16"
          />
        </span>
        </div>

        <div className="pr-4">
          <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
          >
            {[10, 30, 50, 100, 200, 1000].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
            ))}
          </select>
        </div>

      </div>
  )
}