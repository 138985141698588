import React, {createContext, useContext, useState} from "react";

const PageNameContext = createContext({
  pageInfo: {
    name: "Dashboard",
    desc: "A default home page to use",
  },
  setInfo: () => {
  },
});

export function PageNameProvider({children}) {
  const [pageInfo, setPageInfo] = useState({
    name: "Home",
    desc: "A default home page to use",
  });

  return (
      <PageNameContext.Provider
          value={{pageInfo: pageInfo, setInfo: setPageInfo}}
      >
        {children}
      </PageNameContext.Provider>
  );
}

export function usePageNameContext() {
  const pageNameCtx = useContext(PageNameContext);

  if (!pageNameCtx) {
    throw new Error(
        "usePageNameContext must be used within a PageNameProvider"
    );
  }

  return pageNameCtx;
}
