import {useForm} from "react-hook-form";

import PatientStatusEnum from "../../constants/PatientStatusEnum";
import usePatients from "../../data/usePatients";
import {ButtonFormInput} from "../Inputs/ButtonFormInput";
import {DateInput} from "../Inputs/DateInput";
import {FrailtyScore} from "../../constants/FrailtyScore";
import SelectInput from "../Inputs/SelectInput";
import SwitchInput from "../Inputs/SwitchInput";
import {TextInput} from "../Inputs/TextInput";
import useInjuryTypes from './../../data/useInjuryTypes';
import useMedications from "./../../data/useMedications";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';
import Flyout from './../../layouts/Flyout';


export default function AddPatient({open, setOpen}) {
  const {medications} = useMedications();
  const {injuryTypes} = useInjuryTypes();
  const {create} = usePatients();
  const {saveHandlers} = useDefaultCRUDHandlers("Patient");
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: {
      canNOK: false,
      canSms: false,
      canEmail: false,
      currentStatus: 0,
    },
  });

  const createPatient = (data) => {
    create.mutate(data, {
      onSuccess: () => {
        setOpen(false);
        saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError
    });
  }

  if (medications.isLoading || injuryTypes.isLoading) {
    return null;
  } else {
    return (
        <Flyout
            open={open}
            setOpen={setOpen}
            title="Add Patient"
        >
          <form onSubmit={handleSubmit(createPatient)}>
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                  label="First Name:"
                  register={register}
                  required
                  registerName="firstname"
              />
              <TextInput
                  label="Last Name:"
                  register={register}
                  required
                  registerName="lastname"
              />

              <TextInput
                  label="NHS#:"
                  register={register}
                  required
                  registerName="nhsNumber"
              />
              <TextInput
                  label="Phone:"
                  register={register}
                  required
                  registerName="phone"
              />

              <TextInput
                  label="Email:"
                  register={register}
                  required
                  registerName="email"
                  cols={2}
              />

              <SelectInput
                  label="Injury Type:"
                  register={register}
                  registerName="injuryTypeId"
                  options={injuryTypes.data?.map((inType) => ({
                    value: inType.id,
                    label: inType.name,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />
              <SelectInput
                  label="Medication:"
                  register={register}
                  registerName="medicationId"
                  options={medications.data?.map((med) => ({
                    value: med.id,
                    label: med.name,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />

              <TextInput
                  label="Dosage:"
                  register={register}
                  registerName="dosage"
                  cols={2}
              />

              <DateInput
                  label="Injured On:"
                  register={register}
                  registerName="injuryDate"
                  cols={1}
              />
              <DateInput
                  label="Resume On:"
                  register={register}
                  registerName="resumeOn"
                  cols={1}
              />
              <SelectInput
                  label="Frailty:"
                  register={register}
                  registerName="frailtyScore"
                  setValue={setValue}
                  cols={1}
                  watch={watch}
                  classes="text-black"
                  options={Object.values(FrailtyScore).map((score, i) => ({
                    value: i + 1,
                    label: `${i + 1} - ${FrailtyScore[i + 1]}`,
                  }))}
              />
              <SelectInput
                  label="Current Status:"
                  register={register}
                  registerName="currentStatus"
                  options={Object.values(PatientStatusEnum).map((status, i) => ({
                    value: i,
                    label: status,
                  }))}
                  watch={watch}
                  setValue={setValue}
              />
            </div>

            <div className="mt-4">
              <p className="ml-4 font-semibold">Contact Preferences</p>

              <div className="mt-8 flex justify-between">
                <SwitchInput
                    label="NOK"
                    register={register}
                    registerName="canNOK"
                    watch={watch}
                    setValue={setValue}
                />
                <SwitchInput
                    label="SMS"
                    register={register}
                    registerName="canSms"
                    watch={watch}
                    setValue={setValue}
                />
                <SwitchInput
                    label="Email"
                    register={register}
                    registerName="canEmail"
                    watch={watch}
                    setValue={setValue}
                />
              </div>
            </div>

            <div
                // className={`mt-16 grid grid-cols-2 gap-x-8`}
                className="flex justify-end mt-16"
            >
              {/* <ButtonFormInput
              label="Save & Send Inital Prompt"
              isSubmit={false}
              onClick={() => console.log("send a thing!")}
            /> */}
              <ButtonFormInput
                  label="Save Patient"
                  isSubmit={true}
                  isBlue={true}
                  onClick={null}
                  classes="w-1/2"
              />
            </div>

            {Object.keys(errors).length > 0 && (
                <div className="mt-4 px-4 text-right text-red-500">
                  <p>Please fill in all required fields!</p>
                </div>
            )}
          </form>
        </Flyout>
    );
  }
}
