import {useMutation, useQueryClient} from "@tanstack/react-query";

import useApiHelper from "../services/useApiHelper";

export default function useNOK() {
  const queryClient = useQueryClient();
  const {post, put} = useApiHelper();

  const create = useMutation((newNOK) => post(`/nextofkin`, newNOK), {
    onSuccess: (_, updatedNOK) => {
      queryClient.invalidateQueries(["patients"]);
      queryClient.invalidateQueries(["patient", updatedNOK.patientId]);
    },
  });

  const update = useMutation(
      (updatedNOK) => put(`/nextofkin/${updatedNOK.id}`, updatedNOK),
      {
        onSuccess: (_, updatedNOK) => {
          queryClient.invalidateQueries(["patients"]);
          queryClient.invalidateQueries(["patient", updatedNOK.patientId]);
        },
      }
  );

  return {
    create,
    update,
  };
}
