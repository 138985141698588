import {Listbox, Transition} from "@headlessui/react";
import classNames from "../../utils/classNames";
import {Fragment} from "react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";

export default function SelectInput({
                                      label,
                                      register,
                                      registerName,
                                      options,
                                      setValue,
                                      watch,
                                      className
                                    }) {
  const selectValue = watch(registerName);

  return (
      <div className={className}>
        <Listbox
            {...register(registerName)}
            value={selectValue}
            onChange={(e) => setValue(registerName, e)}
        >
          {({open}) => (
              <>
                <div className="w-full relative">
                  <Listbox.Button
                      className={classNames(
                          "relative pl-6 bg-gray-200 w-full font-semibold rounded-full py-5 text-left cursor-pointer"
                      )}
                  >
                <span className="block truncate">
                  {selectValue !== undefined && selectValue !== null
                      ? options.find((e) => e.value === selectValue).label
                      : label}
                </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-700"
                      aria-hidden="true"
                  />
                </span>
                  </Listbox.Button>

                  <Transition
                      show={open}
                      appear={true}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                    <Listbox.Options
                        className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto">
                      {options.map((opt) => (
                          <Listbox.Option
                              key={opt.value}
                              className={({active}) =>
                                  classNames(
                                      active ? "bg-coag-lightblue-500" : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-8 pr-4"
                                  )
                              }
                              value={opt.value}
                          >
                            <>
                        <span
                            className={classNames(
                                selectValue === opt.value
                                    ? "font-semibold"
                                    : "font-normal",
                                "block truncate"
                            )}
                        >
                          {opt.label}
                        </span>

                              {selectValue === opt.value ? (
                                  <span
                                      className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                              ) : null}
                            </>
                          </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
          )}
        </Listbox>
      </div>
  );
}
