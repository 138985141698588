// Landing.js
// Shown when the user first visits the site, no matter patient, staff or member of the public

import React from 'react';
import PillButton from '../../components/Buttons/PillButton';
import FeedbackBanner from '../../components/shared/FeedbackBanner';
import { useNavigate } from 'react-router';
import LandingInfoBanner from "../../components/shared/LandingInfoBanner";

export default function Landing() {

  const navigate = useNavigate();

  return (
    <div className='px-0'>

      <div className='mx-0 2xl:mx-16 my-8 md:my-16 xl:my-20 grid grid-cols-2 xl:grid-cols-4 gap-12'>

        <div className='col-span-2'>
          <h1 className='p-3 text-4xl md:text-5xl font-bold text-gray-600'>Welcome to Head Track</h1>
          <p className='p-3 text-xl md:text-2xl font-bold'>Roughly 50% of head injury patients are taking anticoagulant
            medications</p>
          <p className='p-3 text-lg md:text-xl text-gray-600'>
            In most cases anticoagulants will be paused to prevent further bleeding.
            Doctors will decide when it is safe to resume, but it can vary from a few days to several weeks.
            It is very important to restart the blood thinners on time as they play a critical role in
            preventing strokes, heart attacks and blood clots.
          </p>

          {/* <PillButton text='Register Interest' onClick={() => {
          }} classes={'m-3'} isSolid={true} /> */}
          <PillButton text='Nurses Login' onClick={() => {
            navigate("/login")
          }} classes={'m-3'} />

        </div>

        <div className='col-span-2 flex justify-center items-center aspect-[16/9] xl:aspect-auto'>
          <iframe
            src="https://player.vimeo.com/video/887262264?badge=0&amp;autoplay=0&amp;loop=0&amp;muted=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
            width="100%"
            height="100%"
            frameborder="0"
            title="Head Track Video"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>

      </div>

      <LandingInfoBanner />

      <FeedbackBanner />

    </div>
  );
}
