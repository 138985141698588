import classNames from "../../utils/classNames";

export const PromptButton = ({
                               label,
                               disabled,
                               isSubmit = false,
                               onClick = () => {
                               },
                               filled = false,
                               classes,
                             }) => {
  return (
      <button
          className={classNames(
              classes,
              filled ? "bg-white text-coag-blue-600" : "bg-transparent text-white border-white border-[3px]",
              "px-14 py-3 relative rounded-full text-sm font-medium leading-4 shadow-sm"
          )}
          disabled={disabled}
          onClick={onClick}
          type={`${isSubmit ? "submit" : "button"}`}
      >
        {label}
      </button>
  );
};
