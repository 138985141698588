import PromptCard from "../../components/Card/PromptCard";

export default function Success() {
  // const { user } = useAuth();
  // const { patient } = usePatient(user?.uid);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (!patient.isLoading) {
  //     setIsLoading(false);
  //   }
  // }, [patient.isLoading, patient.data, user?.uid]);

  // if (isLoading) {
  //   return <LoadingWheel />;
  // }

  return (
      <PromptCard title="All done!">
        {/* <p className="text-lg">
        You can contact your GP or 111 for further information regarding your{" "}
        {patient.data.injuryType.name} aftercare or{" "}
        {patient.data.medication.name} medication
      </p> */}

        <p className="text-lg">
          You can contact your GP or 111 for further information regarding your injury aftercare or medication.
        </p>
      </PromptCard>
  );
}
