export const TextAreaInput = ({
                                label,
                                register,
                                registerName,
                                className,
                                required = false,
                                cols = 1,
                              }) => {
  return (
      <div className={`flex flex-col rounded-md bg-feedback-banner ${className}`}>
        <p className="select-none p-4">
          {label}
        </p>
        <textarea
            {...register(registerName, {required: required})}
            className="block flex-1 border-0 bg-transparent p-5 placeholder:text-gray-400 focus:ring-0 resize: both; overflow: auto"
            style={{minHeight: '300px'}}
            placeholder="Text you enter here will be added to the patient's history, timestamped with your name and the current date."
        />
      </div>
  );
};