import {getApp, getApps, initializeApp} from "firebase/app";
import {getAdditionalUserInfo, getAuth, GithubAuthProvider, GoogleAuthProvider, signInWithPopup,} from "firebase/auth";
import axios from 'axios'

import {FirebaseErrorHandler, FirebaseSuccessHandler} from "./FirebaseNotificationHandler";
import {firebaseAPIKey} from './../config';

const firebaseConfig = {
  apiKey: firebaseAPIKey,
  authDomain: "coag-track.firebaseapp.com",
  projectId: "coag-track",
  storageBucket: "coag-track.appspot.com",
  messagingSenderId: "108891518447",
  appId: "1:108891518447:web:90facecb6b6206de19721a",
  measurementId: "G-CYY6E2KXBV"
};

const api = axios.create({
  baseURL: "https://localhost:7266/v1"
})
const app = !getApps().length ? initializeApp({apiKey: firebaseAPIKey}) : getApp();
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({prompt: "select_account"});

const signInWithGoogle = () => {
  return new Promise((resolve, reject) => {
    signInHandler(googleProvider).then((result) => {
      resolve(result);
    });
  });
};

const githubProvider = new GithubAuthProvider();
githubProvider.setCustomParameters({prompt: "select_account"});

const signInWithGitHub = () => {
  return new Promise((resolve, reject) => {
    signInHandler(githubProvider).then((result) => {
      resolve(result);
    });
  });
};

//Need to return the promises for the notification handler
function signInHandler(provider) {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
        .then(async (result) => {
          const additionalUserInfo = getAdditionalUserInfo(result);
          if (additionalUserInfo.isNewUser) {
            //   console.log(`NEW USER: ${result.user.displayName}`);
            //Register new user with API
            await api.post("/account", result, {headers: {Authorization: `Bearer ${result.user.stsTokenManager.accessToken}`}})
          } else {
            //   console.log(`EXISTING USER: ${result.user.displayName}`);
          }
          // console.log(`LOGIN: ${JSON.stringify(result)}`);
          resolve(FirebaseSuccessHandler(result));
        })
        .catch((error) => {
          resolve(FirebaseErrorHandler(error));
        });
  });
}

export {auth}; //, signInWithGoogle, signInWithGitHub };
