import {useMutation} from "@tanstack/react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAuthTokens() {
  const {put} = useApiHelper();

  const validate = useMutation(
      (body) => put('/Auth/validate', body),
      {
        onSuccess: (res) => {
          console.log(res)
          if (res.success) {
            console.log("Success! Token has been validated!");
          } else {
            throw new Error("Token validation failed");
          }
        },
        onError: (error) => {
          console.log(error.message);
        },
      }
  );

  return {
    validate,
  };
}
