import {DateTime} from "luxon";

import PatientStatusEnum from "../../constants/PatientStatusEnum";
import usePatient from "../../data/usePatient";
import {ButtonFormInput} from "../Inputs/ButtonFormInput";
import useNotification from "../../hooks/useNotifications";
import {useEffect, useState} from "react";
import LoadingWheel from "../shared/LoadingWheel";
import getResumeInDays from "../../utils/daysSince";
import PillButton from "../Buttons/PillButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDownShortWide,
  faArrowDownWideShort,
  faCircleQuestion,
  faEye,
  faEyeSlash,
  faTrashCan,
  faTrashCanArrowUp
} from "@fortawesome/free-solid-svg-icons";

function StatTile({title, value, children = null}) {
  return (
      <div className="text-center">
        <h4 className="text-lg font-semibold">{title}{children}</h4>
        <h2 className="text-2xl font-semibold">{value}</h2>
      </div>
  );
}

function MapSourceType(sourceNumber, sourceName) {
  switch (sourceNumber) {
      // 0 = System
    case 0:
      return (
          <p className="text-cyan-600 font-bold">Headtrack System</p>
      )
      // 1 = Staff (nurse or system admin)
    case 1:
      return (
          <p className="text-sky-600 font-bold">Staff: {sourceName}</p>
      )
      // 2 = Patient
    case 2:
      return (
          <p className="text-emerald-600 font-semibold">Patient</p>
      )
      // 3 = NOK
    case 3:
      return (
          <p className="text-sky-600 font-semibold">Next of Kin</p>
      )
      // Other = System
    default:
      return (
          <p className="text-cyan-600 font-medium">Headtrack System</p>
      )
  }
}

export default function PatientDetails({patientId, onEdit, onNote, setFrailtyOpen}) {
  const [sortDesc, setSortDesc] = useState(true);
  const [seeHidden, setSeeHidden] = useState(false);
  const {addNotification} = useNotification();
  const {patient, sendPrompt, deleteNote, toggleHidden, restoreNote} = usePatient(patientId, seeHidden);

  const handleSendPrompt = () => {
    sendPrompt.mutate(null, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Prompt Sent",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Unable to Send Prompt",
        });
      },
    });
  }

  const handleDelete = (history) => {
    console.warn(history)
    deleteNote.mutate(history.id, {
          onSuccess: () => {
          }
        }
    )
  }

  const handleRestore = (history) => {
    console.warn(history)
    restoreNote.mutate(history.id, {
          onSuccess: () => {
          }
        }
    )
  }

  const handleToggleHidden = () => {
    toggleHidden.mutate(!seeHidden, {
      onSuccess: () => {
        setSeeHidden(!seeHidden)
      }
    })
  }

  useEffect(() => {
    // console.log(patient.data)
  }, [patient.isLoading, patient.data, patientId])


  if (patient.isLoading) {
    return <LoadingWheel/>;
  }

  return (
      <div>
        <div className="grid grid-cols-2 my-8 gap-8">
          <StatTile
              title="Injury Date"
              value={DateTime.fromISO(patient.data.injuryDate)
                  .setLocale("en-gb")
                  .toLocaleString(DateTime.DATE_SHORT)}
          />
          <StatTile
              title="Resumption Date"
              value={getResumeInDays(patient.data.resumeOn)}
          />
          <StatTile title="Latest Frailty Score" value={patient.data.frailtyScore}>
            <FontAwesomeIcon className={"rounded-full p-1 hover:ring-4 hover:ring-blue-500"} icon={faCircleQuestion}
                             onClick={x => setFrailtyOpen(true)}/>
          </StatTile>
          <StatTile
              title="Current Status"
              value={PatientStatusEnum[patient.data.currentStatus]}
          />
        </div>

        <div className="max-h-[28rem] mt-4 px-8 pt-4 pb-10 bg-gray-200 rounded-3xl space-y-4 overflow-y-auto">

          <div className="flex justify-between items-center">
            <p className="font-bold text-xl">
              Patient History
              <FontAwesomeIcon className="rounded-full p-1 hover:ring-4 hover:ring-blue-500"
                               icon={sortDesc ? faArrowDownWideShort : faArrowDownShortWide}
                               onClick={x => setSortDesc(!sortDesc)}/>
              <FontAwesomeIcon className="rounded-full p-1 hover:ring-4 hover:ring-blue-500"
                               icon={seeHidden ? faEye : faEyeSlash}
                               onClick={handleToggleHidden}/>
            </p>
            <PillButton text="Add Freeform Note to Patient's History" isSolid={false}
                        onClick={() => onNote(patient.data)}/>
          </div>

          {patient.data.history.length === 0 && (
              <p>There is no history for this patient yet.</p>
          )}

          {/* Sort the history by date, then map it to the UI */}
          {patient.data.history.slice().sort((a, b) => sortDesc ? new Date(b.date) - new Date(a.date) : new Date(a.date) - new Date(b.date)).map((history, i) => (

              <div key={i}
                   className={"border-2 border-black rounded-xl px-6 p-4 space-y-4 " + (history.deletedBy == null ? "" : "bg-orange-300")}>

                <div className="flex justify-between">
                  <span>{DateTime.fromISO(history.date).setLocale("en-gb").toLocaleString(DateTime.DATE_SHORT)}</span>
                  <span>{getResumeInDays(history.date)}</span>
                </div>

                <div>
                  {/* Regex to conditionally colour the text red if the patient declines to resume their medication */}
                  <p className={history.note == null ? "" : history.note.match(/Patient Status.*from.*to.*Declined/) ? 'text-red-500' : ''}>
                    {history.note == null ? "" : history.note}
                  </p>
                </div>

                <div className="flex justify-between">
                  {MapSourceType(history.source, history.sourceName)}
                  {
                    history.deletedBy == null ?
                        <span className="rounded-full p-1 hover:ring-4 hover:ring-blue-500"
                              onClick={x => handleDelete(history)}><FontAwesomeIcon
                            icon={faTrashCan}/></span>
                        :
                        <span className="rounded-full p-1 hover:ring-4 hover:ring-blue-500"
                              onClick={x => handleRestore(history)}><FontAwesomeIcon
                            icon={faTrashCanArrowUp}/></span>
                  }
                </div>

              </div>
          ))}

        </div>

        <div className="mt-16 grid grid-cols-2 gap-x-8">
          <ButtonFormInput
              label="Send Prompt"
              disabled={Boolean(
                  //Only disabled if the patient has restarted
                  patient.data.currentStatus === 2
              )}
              onClick={() => handleSendPrompt()}
          />
          <ButtonFormInput
              onClick={() => onEdit(patient.data)}
              isBlue
              label="Edit Patient Details"
          />
        </div>
      </div>
  );
}
