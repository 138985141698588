import PillButton from "../Buttons/PillButton";

export default function FrailtyDescribed({}) {

  const getRow = (frailty) => {
    return <div className={"bg-gray-200 rounded-3xl p-2 mr-2 flex flex-row justify-between"}>
      <div>
        <h1 className={"font-bold text-xl"}>{frailty.title}</h1>
        <p>{frailty.body}</p>
      </div>
      <img style={{maxWidth: "50%"}} className={"rounded-3xl"} alt={"Image describing " + frailty.title}
           src={frailty.imgUrl}/>
    </div>
  };

  //The content here is based off of my very basic understanding of the rockwood frailty scale.
  //The bodies should be rewritten by someone with more understanding than me.
  //Images should also be substituted for those that we have license for.

  const frailityInfo = [
    {
      title: "1 - Very Fit",
      body: "You are fit and active, exercise regularly and able to complete your day without assistance. This also means that you are not currently suffering from a disease or receiving medical care.",
      imgUrl: "https://d2lfsu1qnyxzxu.cloudfront.net/cms/senior-runnor-f.jpg"
    },
    {
      title: "2 - Well / Fit",
      body: "You aren't actively / frequently exercising, but are active in your days while requiring no assistance or medical care for ongoing health issues.",
      imgUrl: "https://s3-us-west-1.amazonaws.com/stanford.ucomm.newsms.media/wp-content/uploads/2016/04/21143726/13763-walking_teaser.jpg"
    },
    {
      title: "3 - Managing Well",
      body: "You suffer from some mild or well controlled health issues. You aren't regularly active, but are able to walk without issue.",
      imgUrl: "https://images.theconversation.com/files/295477/original/file-20191003-52837-1f94c47.jpg"
    },
    {
      title: "4 - Vulnerable",
      body: "You aren't dependent on others, but conditions may impair your activites. You may feel tired or 'Slowed Up' during the day.",
      imgUrl: "https://thefriendlies.org.au/wp-content/uploads/2022/03/Website-News-Hospital-walking-stick2.jpg"
    },
    {
      title: "5 - Mildly Frail",
      body: "You may need help with certain, heavier activities and find day to day life is more of a struggle.",
      imgUrl: "https://www.passionatepeople.invacare.eu.com/wp-content/uploads/2021/12/1639350282_3402_EU_Walking-frames.jpg"
    },
    {
      title: "6 - Moderately Frail",
      body: "You require help for any outing and may require light care in order to dress and bathe in a comfortable manner.",
      imgUrl: "https://www.abilitysuperstore.com/cdn/shop/articles/ZIMMER.jpg"
    },
    {
      title: "7 - Severely Frail",
      body: "You can no longer look after your self and so rely on others. You may be in a wheelchair and suffer from severe mobility issues.",
      imgUrl: "https://www.nurseregistry.com/wp-content/uploads/2016/09/iStock-599258384-small.jpg"
    },
    {
      title: "8 - Very Severely Frail",
      body: "You are unable to do anything without assistance and are unlikely to recover from even a minor illness.",
      imgUrl: "https://mobilityfurniturecompany.co.uk/wp-content/uploads/Homecare-Beds-For-Use-At-Home-1.jpg"
    },
    {
      title: "9 - Terminally Ill",
      body: "Your care has been ramped up in order to improve your quality of life. You aren't expected to live more than 6 months.",
      imgUrl: "https://dm1zcrsul8wju.cloudfront.net/sites/rcn_nspace/files/styles/full_width_banner/public/End_of_Life-tile©iStock_964_2.jpg"
    }
  ]

  return (
      <div>
        <div className={"max-h-[70vh] overflow-y-auto space-y-4"}>
          {
            frailityInfo.map(x => getRow(x))
          }
        </div>
        <div className={"flex flex-row justify-center"}>
          <PillButton classes={"m-2 "} text={"Find Out More"}
                      onClick={x => window.open("https://www.england.nhs.uk/south/wp-content/uploads/sites/6/2022/02/rockwood-frailty-scale_.pdf", "_blank", "noreferrer")}/>
        </div>
      </div>
  );
}