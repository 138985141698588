import {useEffect, useState} from "react";

import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";

import {isSignInWithEmailLink, signInWithEmailAndPassword, signInWithEmailLink,} from "firebase/auth";

import useAuth from "../../hooks/useAuth";
import useNotifications from "../../hooks/useNotifications";
import LoadingWheel from "../../components/shared/LoadingWheel";
import useAuthTokens from "../../data/useAuthTokens";

export default function Login() {

  const navigate = useNavigate();
  const location = useLocation();
  const apiKey = new URLSearchParams(location.search).get("apiKey");
  const from = location.state?.from?.pathname || "/";
  const {addNotification} = useNotifications();
  const {register, handleSubmit} = useForm();
  const {auth, user, loading, handleLogout} = useAuth();
  const [email, setEmail] = useState(
      window.localStorage.getItem("emailForSignIn")
  );

  const tokenShortener = new URLSearchParams(location.search).get("s");
  const tokenLongString = new URLSearchParams(location.search).get("t");
  const {validate} = useAuthTokens();

  const doLogin = async (data) => {
    try {
      const loginRes = await signInWithEmailAndPassword(
          auth,
          data.email,
          data.password
      );
      console.log(loginRes);
    } catch (err) {
      console.error(err);
      addNotification({
        variant: "error",
        primaryText: err.message.replace("Firebase: ", ""),
      });
    }
  };

  const handleEmailSignIn = () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // If no email in local storage, prompt for email
      if (!email)
        setEmail(window.prompt("Please provide your email for confirmation"));

      signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            console.log(result.user);

            navigate("/prompt/resume");
            // Navigate to non-admin section
          })
          .catch((error) => {
            console.log(error);
            addNotification({
              variant: "error",
              primaryText: "Unable to sign in with email link",
            });
          });
    } else {
      // Handle token links in this part
      console.log("tokenShortener", tokenShortener);
      console.log("tokenLongString", tokenLongString);

      let body = {
        tokenString: (tokenLongString ? tokenLongString : tokenShortener),
        markAsUsed: false
      };

      validate.mutate(body,
          {
            onSuccess: (res) => {
              console.log("Success call found in Login.js");
              console.log(res);
              navigate(`/prompt/resume/${tokenLongString ? tokenLongString : tokenShortener}/${res.data}`);
            },
            onError: () => {
              console.log("Failure call found in Login.js");
              addNotification({
                variant: "error",
                primaryText: "Unable to sign in with token link. Has your link expired?",
              });
            },
          }
      );

    }
  };

  useEffect(() => {
    if (!apiKey && user) navigate(from, {replace: true});
  }, [user]);

  useEffect(() => {
    if ((apiKey || tokenLongString || tokenShortener) && !loading) {
      // If user is logged in, log them out first
      if (user) handleLogout(false);

      handleEmailSignIn();
    }
  }, [loading]);

  return (
      <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:max-w-md">
            <div className="flex flex-col justify-center leading-none font-medium">
              <img src="head-track.svg" alt="Head Track logo" className="w-10/12 mx-auto"/>
            </div>
          </div>

          <div className="mt-10 sm:mb-32 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white border py-8 px-4 shadow sm:rounded-lg sm:px-10">
              {loading ? (
                  <LoadingWheel/>
              ) : (
                  <>
                    <h2 className="mb-6 text-3xl font-bold tracking-tight text-gray-900">
                      Sign in
                    </h2>
                    <form className="space-y-6" onSubmit={handleSubmit(doLogin)}>
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-m ml-1 font-medium text-gray-700"
                        >
                          Email Address
                        </label>
                        <div className="mt-1">
                          <input
                              {...register("email", {required: true})}
                              type="email"
                              autoComplete="email"
                              placeholder="example@nhs.net"
                              required
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                            htmlFor="password"
                            className="block text-m ml-1 font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                              {...register("password", {required: true})}
                              type="password"
                              autoComplete="current-password"
                              placeholder="example123"
                              required
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div> */}

                      <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-m font-bold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          Sign in
                        </button>
                      </div>
                      <div>
                        <button className="w-full">
                          <a
                              href="/"
                              className="flex justify-center rounded-md border border-transparent bg-gray-200 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          >
                            Return to the homepage
                          </a>
                        </button>
                      </div>
                    </form>
                  </>
              )}
            </div>
          </div>
        </div>
      </>
  );
}
